<template>
  <div>
    <!-- Menu Mobile -->
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="drawerMobile"
      app
      clipped
      temporary
      style="z-index: 500"
    >
      <v-list dense>
        <v-list-item
          v-for="(item, i) in rotasPermitidas(items)"
          :key="i"
          link
          :to="item.link"
          color="primary"
          :id="item.mobile_id"
          :class="{ 'mx-0 px-0 d-block': item.tipo == 'CONTRIBUICOES' }"
        >
          <div v-if="item.tipo == 'CONTRIBUICOES'" class="py-4 text-center">
            <button
              class="destaque-desk"
              @click="$router.push('/aluno/dashboard/pagamento-parcelas')"
            >
              Pagamento parcelas
            </button>
          </div>
          <div class="d-flex" v-else>
            <v-list-item-icon>
              <v-icon
                class="icon"
                :class="{
                  'grey--text text--darken-4': item.tipo == 'CONTRIBUICOES'
                }"
                >{{ item.icon }}</v-icon
              >
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                class="text"
                :class="{
                  'grey--text text--darken-4': item.tipo == 'CONTRIBUICOES'
                }"
                >{{ item.title }}</v-list-item-title
              >
            </v-list-item-content>
          </div>
        </v-list-item>
      </v-list>

      <div class="footerMenu pa-3">
        <div class="text-subtitle-1 text-center font-weight-light">
          Turmas / Eventos
        </div>
        <div class="turmas mb-3">
          <div
            class="turma my-2"
            :key="index"
            v-for="(item, index) in renderTurmas"
          >
            <v-btn
              :class="{ turmaAtiva: item.id == getTurmaAtiva }"
              @click="SET_TURMA(item.id)"
              depressed
              class="turmaBtn my-1 text-subtitle-2 text-truncate font-weight-normal"
              block
              color="rgba(50,50,50,0.1)"
              style="text-overflow: ellipsis"
            >
              <template style="text-overflow: ellipsis">
                <v-icon left> mdi-school-outline </v-icon>
                <div class="text-truncate">
                  {{ item.nome | truncateManual }}
                </div>
              </template>
            </v-btn>
          </div>
        </div>
      </div>

      <v-list dense>
        <v-list-item link color="primary" @click="processaLogout()">
          <v-list-item-icon>
            <v-icon class="icon">mdi-logout-variant</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text">Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Menu PC -->

    <v-navigation-drawer
      v-if="$vuetify.breakpoint.mdAndUp"
      v-model="drawer"
      :mini-variant="mini"
      app
      clipped
      permanent
    >
      <v-list dense>
        <v-list-item
          v-for="(item, i) in rotasPermitidas(items)"
          :key="i"
          link
          :to="item.link"
          color="primary"
          :id="item.id"
          :class="{ 'mx-0 px-0 d-block': item.tipo == 'CONTRIBUICOES' }"
        >
          <div
            v-if="item.tipo == 'CONTRIBUICOES'"
            :class="{ 'py-0': mini, 'py-4': !mini }"
          >
            <div>
              <div v-if="mini" class="text-center">
                <button
                  class="destaque-mobile"
                  @click="$router.push('/aluno/dashboard/pagamento-parcelas')"
                >
                  <v-icon color="white">mdi-view-week-outline</v-icon>
                </button>
              </div>
              <div v-if="!mini" class="text-center">
                <button
                  class="destaque-desk"
                  @click="$router.push('/aluno/dashboard/pagamento-parcelas')"
                >
                  Pagamento parcelas
                </button>
              </div>
            </div>
          </div>

          <div class="d-flex" v-else>
            <v-list-item-icon>
              <v-icon
                class="icon"
                :class="{
                  'grey--text text--darken-4': item.tipo == 'CONTRIBUICOES'
                }"
                >{{ item.icon }}</v-icon
              >
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                class="text"
                :class="{
                  'grey--text text--darken-4': item.tipo == 'CONTRIBUICOES'
                }"
                >{{ item.title }}</v-list-item-title
              >
            </v-list-item-content>
          </div>
        </v-list-item>
      </v-list>

      <div class="footerMenu pa-3" v-if="!mini && !$vuetify.breakpoint.mdAndUp">
        <div class="text-subtitle-1 text-center font-weight-light">
          Turmas / Eventos
        </div>
        <div class="turmas mb-3">
          <div
            class="turma my-2"
            :key="index"
            v-for="(item, index) in renderTurmas"
          >
            <v-btn
              :class="{ turmaAtiva: item.id == getTurmaAtiva }"
              @click="SET_TURMA(item.id)"
              depressed
              class="turmaBtn my-1 text-subtitle-2 text-truncate font-weight-normal"
              block
              color="rgba(50,50,50,0.1)"
              style="text-overflow: ellipsis"
            >
              <template style="text-overflow: ellipsis">
                <v-icon left> mdi-school-outline </v-icon>
                <div class="text-truncate">
                  {{ item.nome | truncateManual }}
                </div>
              </template>
            </v-btn>
          </div>
          <div id="menu-web-criar-turma">
            <v-btn
              @click="OPEN_CREATE_NEW_ROOM()"
              depressed
              class="my-1 text-subtitle-2 text-truncate white--text"
              block
              color="primaryDash"
              style="text-overflow: ellipsis"
            >
              <template>
                <v-icon left> mdi-plus-box </v-icon>
                <div>Novo evento/turma</div>
              </template>
            </v-btn>
          </div>
        </div>
      </div>

      <v-list dense>
        <v-list-item link color="primary" @click="processaLogout()">
          <v-list-item-icon>
            <v-icon class="icon">mdi-logout-variant</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text">Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { bus } from "@/main";
const TOGGLE_MENU = "TOGGLE_MENU";

export default {
  data() {
    return {
      drawer: true,
      mini: false,
      menuAtivoForever: true,
      drawerMobile: false,
      items: [
        {
          id: "menu-web-contribuicoes",
          mobile_id: "menu-mobile-contribuicoes",
          title: "Pagamento parcelas",
          icon: "mdi-view-week-outline",
          link: "/aluno/dashboard/pagamento-parcelas",
          tipo: "CONTRIBUICOES"
        },
        {
          id: "menu-web-inicio",
          mobile_id: "menu-mobile-inicio",
          title: "Início",
          icon: "mdi-home-outline",
          link: "/aluno/dashboard/inicio",
          tipo: "INICIO"
        },
        {
          id: "menu-web-turma",
          mobile_id: "menu-mobile-turma",
          title: "Turma / Evento",
          icon: "mdi-view-agenda-outline",
          link: "/aluno/dashboard/turma",
          tipo: "TURMA"
        },
        /*{
          id: "menu-web-plano-arrecadacao",
          mobile_id: "menu-mobile-plano-arrecadacao",
          title: "Plano de arrecadação",
          icon: "mdi-calculator-variant-outline",
          link: "/aluno/dashboard/plano-de-arrecadacao",
          tipo: "PLANODEARRECADACAO"
        },*/
        {
          id: "menu-web-cotas",
          mobile_id: "menu-mobile-cotas",
          title: "Cotas individuais",
          icon: "mdi-clipboard-account-outline",
          link: "/aluno/dashboard/cotas-individuais",
          tipo: "COTAS"
        },
        {
          id: "menu-web-participantes",
          mobile_id: "menu-mobile-participantes",
          title: "Participantes",
          icon: "mdi-account-supervisor-outline",
          link: "/aluno/dashboard/alunos",
          tipo: "PARTICIPANTES"
        },
        {
          id: "menu-web-perfil",
          mobile_id: "menu-mobile-perfil",
          title: "Perfil",
          icon: "mdi-account-outline",
          link: "/aluno/dashboard/perfil",
          tipo: "PERFIL"
        },

        {
          id: "menu-web-depositos",
          mobile_id: "menu-mobile-depositos",
          title: "Depósitos",
          icon: "mdi-bank-outline",
          link: "/aluno/dashboard/depositos",
          tipo: "DEPOSITOS"
        },
        {
          id: "menu-web-fornecedores",
          mobile_id: "menu-mobile-fornecedores",
          title: "Fornecedores",
          icon: "mdi-semantic-web",
          link: "/aluno/dashboard/fornecedores",
          tipo: "FORNECEDORES"
        },
        {
          id: "menu-web-pagamentos",
          mobile_id: "menu-mobile-pagamentos",
          title: "Solicitação de Pagamento",
          icon: "mdi-currency-usd",
          link: "/aluno/dashboard/solicitacao-pagamento",
          tipo: "SOLICITACAO-PAGAMENTO"
        },
        {
          id: "menu-web-reunioes",
          mobile_id: "menu-mobile-reunioes",
          title: "Reuniões",
          icon: "mdi-calendar-month",
          link: "/aluno/dashboard/reunioes",
          tipo: "REUNIOES"
        },
        {
          id: "menu-web-extrato-evento",
          mobile_id: "menu-mobile-extrato-evento",
          title: "Relatórios",
          icon: "mdi-finance",
          link: "/aluno/dashboard/extrato",
          tipo: "EXTRATO"
        },
        {
          id: "menu-web-central-atendimento",
          mobile_id: "menu-mobile-central-atendimento",
          title: "Central de Atendimentos",
          icon: "mdi-message",
          link: "/aluno/dashboard/central-de-atendimento",
          tipo: "CENTRAL"
        },
        {
          id: "menu-web-ajuda",
          mobile_id: "menu-mobile-ajuda",
          title: "Ajuda",
          icon: "mdi-help-circle",
          link: "/aluno/dashboard/ajuda",
          tipo: "AJUDA"
        },
        {
          id: "programa-custo-zero",
          mobile_id: "programa-custo",
          title: "Custo Zero",
          icon: "mdi-cash",
          tipo: "CUSTO-ZERO",
          link: "/aluno/dashboard/programa-custo-zero"
        }
      ]
    };
  },
  filters: {
    truncateManual(item) {
      if (item) {
        return item.slice(0, 18);
      } else {
        return "";
      }
    }
  },
  created() {
    bus.$on(TOGGLE_MENU, () => {
      this.mini = !this.mini; // esse aqui controla o toogle do PC
      this.drawerMobile = !this.drawerMobile; // esse controla o toogle do Mobile
    });
  },
  methods: {
    OPEN_CREATE_NEW_ROOM() {
      this.$store.commit("aluno/setModalNovaTurma", true);
    },
    SET_TURMA(id) {
      let turmaID = id;
      this.$store.dispatch("aluno/SET_TURMA_ATIVA", turmaID).then(() => {
        this.$store.dispatch("aluno/RELOAD_ALL");
      });
    },
    currencyTurmaColorActive(idTurma) {
      if (idTurma == this.GET_TURMA_ATIVA) {
        return "rgba(230,230,230,1)";
      } else {
        return "primaryDash";
      }
    },
    processaLogout() {
      this.$store.dispatch("aluno/deslogar");
    },
    rotasPermitidas(lista) {
      let userPermissaoTurma = this.$store.getters[
        "aluno/getPermissaoTurmaAtiva"
      ];

      const globalFCZFlag = this.$store.getters["global/getModuleFCZ"];

      return lista.filter(a => {
        if (a.tipo === "CUSTO-ZERO") {
          if (globalFCZFlag) {
            if (this.$store.getters["aluno/getTurmaConfig"].enableFCZ) {
              return true;
            }
          }
          return false;
        }
        if (this.$store.getters["aluno/userIsAdmin"]) {
          return true;
        }
        if (
          ["PARTICIPANTE"].includes(userPermissaoTurma) &&
          ["DEPOSITOS", "EXTRATO", "INICIO"].includes(a.tipo)
        ) {
          return false;
        }
        if (userPermissaoTurma !== "ADMIN" && ["COTAS"].includes(a.tipo)) {
          return false;
        }
        if (
          [
            "PARTICIPANTES",
            "TURMA",
            "PLANODEARRECADACAO",
            "COTAS",
            "DEPOSITOS",
            "REUNIOES"
          ].includes(a.tipo) &&
          !this.GET_TURMA_ATIVA
        ) {
          return false;
        }
        if (
          ["CONTRIBUICOES", "FORNECEDORES", "SOLICITACAO-PAGAMENTO"].includes(
            a.tipo
          ) &&
          !this.GET_TURMA_ATIVA
        ) {
          return false;
        }
        return true;
      });
    }
  },
  computed: {
    getPermissaoTurma() {
      return this.$store.getters["aluno/getPermissaoTurmaAtiva"];
    },
    getTurmaAtiva() {
      return this.$store.getters["aluno/getTurmaAtiva"];
    },
    renderTurmas() {
      return this.$store.getters["aluno/getTurmas"];
    },
    GetPermissao(n) {
      // nao esta em uso
      if (n == "turma") {
        return this.$store.getters["aluno/getTurmaAtiva"];
      } else if (n == "alunos") {
        return this.$store.getters["aluno/getTurmaAtiva"];
      } else {
        return false;
      }
    },
    GET_TURMA_ATIVA() {
      return this.$store.getters["aluno/getTurmaAtiva"];
    },
    renderImagePerfil() {
      return this.$store.getters["aluno/getUser"].fotoUrl;
    }
  }
};
</script>
<style lang="scss" scoped>
$dashWidthMin: 100px;
$dashWidthMax: 260px;
$hoverlink: rgba(50, 50, 50, 0.03);
.destaque-mobile {
  padding: 8px 16px;
  background-color: $primary;
  color: white;
  font-size: 14px;
  margin: 0px auto;
  border-radius: 0px;
  font-weight: bold;
}
.destaque-desk {
  padding: 8px 16px;
  background-color: $primary;
  color: white;
  font-size: 14px;
  margin: 0px auto;
  border-radius: 10px;
  font-weight: bold;
}
.text-truncate {
  line-height: unset !important;
}
.turmaBtn {
  color: rgba(50, 50, 50, 1);
  &.turmaAtiva {
    color: $primary !important;
  }
}
.perfilDash {
  text-align: center;
  padding: 10px 0px;
}
.menu {
  .link {
    font-size: 13px;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    font-family: "Poppins";
    text-decoration: none;
    display: grid;
    grid-template-columns: 50px 1fr;
    padding: 6px 5px;
    text-align: center;
    border-right: 2px solid rgba(0, 0, 0, 0);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    align-content: center;
    align-items: center;
    align-self: center;
    text-align: left;
    transition: 0.3s all;
    &.mini {
      display: block;
      text-align: center;
      justify-content: center;
      align-self: center;
      align-content: center;
      align-items: center;
    }
    .icon {
      //color: #b0b9c5;
      color: $colorLightGrey;
      transition: 0.3s all;
    }
    .text {
      //color: #b0b9c5;
      color: $colorLightGrey;
      transition: 0.3s all;
    }
    &:hover {
      background-color: $hoverlink;
    }
    &.router-link-active {
      border-right: 2px solid $colorDarkPurple;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      .icon {
        color: $colorDarkPurple;
      }
      & > .text {
        color: $colorDarkPurple;
      }
    }
    &.logout {
      background-color: rgb(170, 50, 50, 0);
      transition: 0.5s all;
      cursor: pointer;
      & > .text {
        color: rgba(170, 50, 50, 0.5);
      }
      &:hover {
        cursor: pointer;
        background-color: rgba(170, 50, 50, 0.1);
      }
    }
  }
}
</style>
