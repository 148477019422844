import axios from "axios";
export default {
  getStatusPix(depositoId) {
    return axios.get(`/v1/deposito/with-pix/${depositoId}`);
  },
  pagarDepositoComSaldo(depositoId) {
    return axios.post(`/v1/deposito/${depositoId}/pagar-com-saldo`);
  },
  gerarPix(depositoId) {
    return axios.post(`/v1/deposito/${depositoId}/gerar-pix`);
  },
  gerarBoletoDaDeposito(depositoId) {
    return axios.post(`/v1/deposito/${depositoId}/gerar-boleto`);
  },
  novoDeposito(payload) {
    return axios.post("/v1/deposito", {
      dataVencimento: payload.dataVencimento,
      descricao: payload.descricao,
      turmaId: payload.turmaId,
      valor: payload.valor
    });
  },
  getPagination(page, pageSize, filtros) {
    return axios.post("/v1/deposito/pagination", {
      descending: true,
      direction: "ASC",
      filtros: {
        id: 0,
        status: filtros.status,
        turmaId: filtros.turmaId
      },
      pageNo: page,
      pageSize: pageSize,
      sortBy: "string"
    });
  }
};
