<template>
  <v-dialog
    v-model="dialog"
    persistent
    :overlay="false"
    max-width="700px"
    transition="dialog-transition"
  >
    <v-card class="pa-4">
      <div class="d-grid grid-col-1-auto">
        <div class="primaryDash--text font-weight-bold text-h5 pa-2">
          Gerar contribuição
        </div>
        <v-btn text @click="$emit('close')">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <div>
        <vCurrentUser :user="user" />
      </div>
      <div class="pa-3">
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              label="Dia do Vencimento"
              :items="listSelectDays"
              v-model="include.diaDoVencimento"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model.lazy="includeValor"
              v-money="$root.money"
              prefix="R$"
              id="valorParaPagar"
              label="Valor do Serviço ou Produto"
            />
          </v-col>
        </v-row>
        <div class="avisoDataMesAno">
          Em caso de uma única parcela, basta colocar a mesma data para ambos
          (inicial e final)
        </div>
        <v-row>
          <v-col cols="12" sm="6">
            <div class="labelForms">Mês/Ano Inicial</div>
            <div class="containForms">
              <div>
                <v-select
                  v-model="includeMesInicial"
                  :items="listSelectMoths"
                  item-text="text"
                  item-id="id"
                  outlined
                  dense
                  label="Mês Inicial"
                  id="mesInicial"
                  return-object
                />
              </div>
              <div>
                <v-select
                  v-model="includeAnoInicial"
                  :items="listSelectYears"
                  item-text="text"
                  item-id="id"
                  outlined
                  dense
                  label="Ano Inicial"
                  id="anoInicial"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="labelForms">Mês/Ano Final</div>
            <div class="containForms">
              <div>
                <v-select
                  v-model="includeMesFinal"
                  :items="listSelectMoths"
                  item-text="text"
                  item-id="id"
                  outlined
                  dense
                  label="Mês Final"
                  id="mesFinal"
                  return-object
                />
              </div>
              <div>
                <v-select
                  v-model="includeAnoFinal"
                  :items="listSelectYears"
                  item-text="text"
                  item-id="id"
                  outlined
                  dense
                  label="Ano Final"
                  id="anoFinal"
                />
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="py-0">
          <v-text-field
            v-model="include.descricao"
            label="Descriação"
          ></v-text-field>
        </div>
        <div class="text-right">
          <v-btn
            :loading="loading"
            color="primary"
            depressed
            @click="cadastrarContribuicao()"
            >Salvar</v-btn
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import serviceContribuicoes from "@/middleware/contribuicoes";
import vCurrentUser from "./currentUserCard.vue";

export default {
  components: {
    vCurrentUser
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      includeValor: 0,
      dialog: true,
      includeMesInicial: null,
      includeAnoInicial: null,
      includeMesFinal: null,
      includeAnoFinal: null,
      include: {
        valor: 0,
        diaDoVencimento: "",
        mesAnoPrimeiraParcela: "",
        mesAnoUltimaParcela: "",
        descricao: ""
      },
      listSelectDays: [],
      listSelectYears: [],
      listSelectMoths: [
        { id: "1", text: "Janeiro" },
        { id: "2", text: "Fevereiro" },
        { id: "3", text: "Março" },
        { id: "4", text: "Abril" },
        { id: "5", text: "Maio" },
        { id: "6", text: "Junho" },
        { id: "7", text: "Julho" },
        { id: "8", text: "Agosto" },
        { id: "9", text: "Setembro" },
        { id: "10", text: "Outubro" },
        { id: "11", text: "Novembro" },
        { id: "12", text: "Dezembro" }
      ]
    };
  },
  beforeMount() {
    this.popularArrayDeDias();
    this.popularArrayDeAnos();
    let mesMomentFormat = moment()
      .add(1, "M")
      .format("M");
    this.includeMesInicial = this.listSelectMoths[mesMomentFormat - 1];
    this.includeAnoInicial = moment()
      .add(1, "M")
      .format("YYYY");

    this.includeMesFinal = this.listSelectMoths[mesMomentFormat - 1];
    this.includeAnoFinal = moment()
      .add(1, "M")
      .format("YYYY");
  },
  methods: {
    popularArrayDeAnos() {
      let array = [];
      for (let i = 1; i <= 6; i++) {
        if (i == 1) {
          let yearMoment = moment();
          array.push({
            id: yearMoment.format("YYYY"),
            text: yearMoment.format("YYYY")
          });
        } else {
          let yearMoment = moment().add(i - 1, "y");
          array.push({
            id: yearMoment.format("YYYY"),
            text: yearMoment.format("YYYY")
          });
        }
      }

      this.listSelectYears = array;
    },
    popularArrayDeDias() {
      let array = [];
      for (let i = 1; i <= 28; i++) {
        array.push({ id: i, text: i });
      }
      this.listSelectDays = array;
    },
    validaDataCompletaInicial() {
      if (Number(this.include.diaDoVencimento) < 1) {
        return {
          status: false,
          message: "Dia do vencimento inválido"
        };
      }
      if (!this.validaMesAno(this.include.mesAnoPrimeiraParcela)) {
        return {
          status: false,
          message: ""
        };
      }
      let parseDataInicial = `${this.include.diaDoVencimento}/${this.include.mesAnoPrimeiraParcela}`;
      let dataInicial = moment(parseDataInicial, "DD/MM/YYYY");
      let dataHoje = moment();
      if (dataInicial.isAfter(dataHoje)) {
        return {
          status: true
        };
      } else {
        return {
          status: false,
          message: "Data inicial não pode ser menor ou igual a data atual"
        };
      }
    },
    verificaData() {
      // validar viabilidade desse validador
      let self = this;
      let dataHoje = moment();
      let dataVencimento = moment(self.include.dataVencimento, "DD/MM/YYYY");
      if (self.$root.validaData(self.include.dataVencimento, "DD/MM/YYYY")) {
        if (!dataHoje.isBefore(dataVencimento)) {
          this.$root.errorModal("Você deve colocar uma data futura");
          return false;
        } else {
          this.dataVencimentoPayload = moment(
            self.include.dataVencimento,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD");
          return true;
        }
      } else {
        this.$root.errorModal("Data inválida");
        return false;
      }
    },
    tratamentoDeValor() {
      let ValorInclude = this.includeValor;
      if (ValorInclude.indexOf(".") !== -1) {
        ValorInclude = ValorInclude.replaceAll(".", "");
      }
      if (ValorInclude.indexOf(",") !== -1) {
        ValorInclude = ValorInclude.replaceAll(",", ".");
      }
      ValorInclude = Number(ValorInclude);
      if (ValorInclude < 0 || !ValorInclude) {
        this.$root.errorModal("Valor não pode ser menor ou igual a zero.");
        return false;
      }
      return Number(ValorInclude);
    },
    validaCamposDataNull() {
      if (
        this.includeAnoInicial &&
        this.includeAnoFinal &&
        this.includeMesInicial &&
        this.includeMesFinal
      ) {
        //existe todos os campos
        return true;
      } else {
        return false;
      }
    },
    validaCampoDia() {
      if (
        this.include.diaDoVencimento == null ||
        this.include.diaDoVencimento <= 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    validaDataFutura() {
      let dataInicial = this.mountDataCompleta("INICIAL");
      let dataFinal = this.mountDataCompleta("FINAL");
      let dataHoje = moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD");

      if (dataInicial.isSame(dataHoje) || dataFinal.isSame(dataHoje)) {
        if (this.include.diaDoVencimento <= dataHoje.date()) {
          this.$root.errorModal("O dia de vencimento não pode ser hoje.");
          return false;
        }
      }

      if (
        !dataInicial.isSameOrAfter(dataHoje) ||
        !dataFinal.isSameOrAfter(dataHoje)
      ) {
        this.$root.errorModal(
          "Data inicial e final deve ser maior que data atual"
        );
        return false;
      }
      // Se a data inicial é depois da final
      if (dataInicial.isAfter(dataFinal)) {
        console.error("#2 isBefore dataInicial dataFinal");
        this.$root.errorModal("Data inicial deve ser antes da data final");
      }
      // Se a data final é antes da inicial
      if (dataFinal.isBefore(dataInicial)) {
        console.error("#3 isBefore dataFinal...dataInicial");
        this.$root.errorModal("Data final deve ser depois da data inicial");
      }
      return true;
    },
    mountDataCompleta(tipo) {
      let diaVencimento = this.include.diaDoVencimento;
      if (tipo === "INICIAL") {
        let mesInicial = this.includeMesInicial.id.padStart(2, "0");
        let anoInicial = this.includeAnoInicial;
        return moment(
          `${anoInicial}-${mesInicial}-${diaVencimento}`,
          "YYYY-MM-DD"
        );
      } else if (tipo === "FINAL") {
        let mesFinal = this.includeMesFinal.id.padStart(2, "0");
        let anoFinal = this.includeAnoFinal;
        return moment(`${anoFinal}-${mesFinal}-${diaVencimento}`, "YYYY-MM-DD");
      } else {
        throw Error("mountDataCompleta: tipo inválido");
      }
    },
    mountDateMonthYear(mes, ano) {
      if (mes && ano) {
        return moment(`${ano}-${mes.id.padStart(2, "0")}`, "YYYY-MM");
      } else {
        throw Error("mountDateMonthYear não pode ter mes e ano nullo");
      }
    },
    mountDateToPayload(mes, ano) {
      if (mes && ano) {
        return this.mountDateMonthYear(mes, ano).format("MM/YYYY");
      } else {
        throw Error("mountDateToPayload não pode ter mes e ano nullo");
      }
    },
    controllerValidadores() {
      if (!this.validaCampoDia()) {
        this.$root.errorModal(
          "Dia do vencimento deve ser maior que zero e menor que 21"
        );
        return false;
      }
      if (!this.tratamentoDeValor()) {
        this.$root.errorModal(
          "Informe um valor válido e positivo para a contribuição"
        );
        return false;
      }
      if (!this.validaCamposDataNull()) {
        this.$root.errorModal(
          "Todos os campos de data devem ser preenchidos corretamente"
        );
        return false;
      }
      if (!this.validaDataFutura()) {
        return false;
      }
      if (this.include.descricao.length < 3) {
        this.$root.errorModal("Descrição está muito pequena");
        return false;
      }
      return true;
    },
    buildPayload() {
      let payload = {
        valor: this.include.valor,
        diaDoVencimento: this.include.diaDoVencimento,
        mesAnoPrimeiraParcela: this.mountDateToPayload(
          this.includeMesInicial,
          this.includeAnoInicial
        ),
        mesAnoUltimaParcela: this.mountDateToPayload(
          this.includeMesFinal,
          this.includeAnoFinal
        ),
        descricao: this.include.descricao,
        usuarioId: this.user.usuarioId
      };

      return payload;
    },
    cadastrarContribuicao() {
      if (!this.controllerValidadores()) return false;
      this.include.valor = this.tratamentoDeValor();
      var payload = this.buildPayload();
      this.lancarContribuicao(payload);
    },
    lancarContribuicao(payload) {
      this.loading = true;
      serviceContribuicoes
        .gerarCotaIndividualPeriodo(payload)
        .then(() => {
          this.$root.infoModal("Cota gerada com sucesso para todos");
          this.loading = false;
          this.$emit("close");
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.avisoDataMesAno {
  padding: 15px;
  margin-bottom: 15px;
  font-size: 13px;
  background-color: rgba(205, 205, 255, 0.2);
  border-radius: 6px;
  color: rgb(50, 50, 105);
}
.containForms {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
.labelForms {
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px;
  margin-bottom: 10px;
}
.containInput {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
</style>
