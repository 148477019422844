<template>
  <div>
    <!-- d-grid grid-col-1-auto -->
    <div class=" pa-2 justify-center align-center">
      <vTitulo>Cotas Individuais</vTitulo>
    </div>
    <div class="px-5">
      <v-row class="justify-center items-center">
        <v-col cols="12" sm="6" class="text-sm-left text-center">
          <v-text-field
            rounded
            hide-details
            depressed
            placeholder="Procurar integrante"
            v-model="search"
            class="font-weight-medium lowShadow white px-2 py-2 mx-4 mx-sm-0"
          >
            <template v-slot:append>
              <v-icon>
                mdi-magnify
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="justify-end items-center">
          <div
            style="display: flex; justify-content: flex-end;  padding: 5px; padding-right: 20px"
          >
            <v-menu
              top
              offset-y
              :close-on-content-click="false"
              v-model="menuFiltro"
            >
              <template v-slot:activator="{ on, attrs }">
                <!-- color="grey lighten-5" -->
                <v-btn
                  elevation="0"
                  style="background-color: white; margin-top: 5px;"
                  class="lowShadow"
                  color="primaryDash"
                  v-bind="attrs"
                  v-on="on"
                  depressed
                >
                  <!--style="color:#9EA2A9;"-->
                  <v-icon style="color:white;">
                    mdi-filter-variant
                  </v-icon>
                  <span class="white--text">Filtros</span>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="item in listaFiltros" :key="item.id">
                  <v-list-item-action>
                    <v-checkbox
                      v-model="filtro"
                      :label="item.label"
                      color="primary"
                      :value="item.id"
                    ></v-checkbox>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
            <!--<v-select
              :items="listaFiltros"
              item-value="id"
              item-text="label"
              v-model="filtro"
              solo
              hide-details
              class="lowShadow white"
            ></v-select>
            -->
          </div>
        </v-col>
      </v-row>
    </div>
    <img
      src="@/assets/design/bubbles.png"
      class="position-absolute mb-n15 ml-7"
    />

    <div class="lista d-flex flex-wrap justify-space-around mt-n10 px-10">
      <div class="cardCota plusBtn lowShadow">
        <v-avatar
          class="click"
          size="100"
          color="#ECEFF5"
          @click="modalCotaParaTodos = true"
        >
          <v-icon size="35">mdi-plus</v-icon>
        </v-avatar>
        <div class="descricaoADD px-4 pt-3">
          Você pode adicionar uma cota para todos os participantes.
        </div>
        <div class="grey--text text--darken-4 py-3 pa-4 text-break">
          <v-btn
            class="br-20 text-body-2 text-break text-wrap"
            depressed
            large
            color="secondaryDash"
            dark
            @click="modalCotaParaTodos = true"
            >cota para todos</v-btn
          >
        </div>
      </div>
      <div
        class="cardCota px-0 userContain lowShadow"
        :key="index"
        v-for="(item, index) in getLista"
      >
        <div class="px-3 pt-5 relative">
          <avatar
            class="d-inline-block mx-auto"
            size="90"
            :imagem="item.fotoUrl"
          />
          <div class="menu">
            <v-menu offset-y transition="slide-y-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text color="white" v-bind="attrs" v-on="on">
                  <v-icon color="#9EA2A9">mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>

              <div class="pa-2 px-3 white">
                <div
                  class="grey--text text--darken-2 pa-2 cursorPointer"
                  @click="abrirContribuicoes(item)"
                >
                  Visualizar contribuições
                </div>
              </div>
            </v-menu>
          </div>
          <div
            class="text-caption absolute top-0 mt-5 ml-2 grey--text text--darken-1"
          >
            USR-{{ item.usuarioId }}
          </div>
        </div>
        <div class="containDescricao">
          <div class="txtFormando text-caption text-break">
            {{ item.nomeFormando }}
          </div>
          <div class="dadosFinanceiros">
            Dados financeiros
          </div>
          <div class="nomecompleto text-break">
            {{ item.nome }} {{ item.sobreNome }}
          </div>
          <div class="telefone  text-break">
            {{ item.telefone ? item.telefone : "Sem telefone" }}
          </div>
          <div class="email  text-break">
            {{ item.email | existeRegistro }}
          </div>
        </div>
        <div class="px-3 py-3 pb-5 text-center">
          <v-btn
            class="br-20 text-body-2"
            large
            depressed
            color="secondaryDash"
            dark
            @click="novaContribuicao(item)"
            >Nova contribuição</v-btn
          >
        </div>
        <div class="totalMensalidade" v-if="false">
          <div class="texto">
            Total do mês
          </div>
          <div class="valor">
            {{ item.metaContribuicao | currency }}
          </div>
        </div>
      </div>
    </div>
    <vMetas
      :item="selectedItem"
      v-if="modalEditar"
      @close="handleClose"
      @closeAlterado="handleAlterado"
    />
    <vContribuicoes
      v-if="modalContribuicoes"
      @close="closeContribuicoes"
      :user="payloadUser"
    />
    <vGerarContribuicoesPorParticipante
      v-if="modalNovaContribuicao"
      @close="closeNovaContribuicao"
      :user="payloadUser"
    />
    <vCotaParaTodos
      v-if="modalCotaParaTodos"
      @close="modalCotaParaTodos = false"
    />
  </div>
</template>

<script>
import serviceTurma from "@/middleware/turmaController";
import vMetas from "./editor";
import avatar from "@/components/global/avatar";
import vContribuicoes from "./modalContribuicoes.vue";
import vCotaParaTodos from "./gerarCotaTodos.vue";
import { normalize } from "@/constants/stringUtils";
import vGerarContribuicoesPorParticipante from "./gerarContribuicoesPorParticipante.vue";

export default {
  components: {
    vMetas,
    avatar,
    vContribuicoes,
    vGerarContribuicoesPorParticipante,
    vCotaParaTodos
  },
  data() {
    return {
      menuFiltro: false,
      filtro: 6,
      listaFiltros: [
        { id: 1, label: "Todos" },
        { id: 6, label: "Autorizado" },
        { id: 2, label: "Comissão" },
        { id: 3, label: "Pendentes" },
        { id: 4, label: "Não autorizados" },
        { id: 5, label: "Contrato cancelado" }
      ],
      modalCotaParaTodos: false,
      modalContribuicoes: false,
      modalNovaContribuicao: false,
      payloadUser: {},
      selectedItem: {},
      search: "",
      modalEditar: false,
      alunosHeader: [
        {
          text: "",
          align: "start",
          value: "perfilUrl",
          sortable: false
        },
        { text: "Participante", value: "nome" },
        { text: "Meta", value: "metaContribuicao" },
        { text: "Ações", value: "", sortable: false }
      ],
      alunos: []
    };
  },
  beforeMount() {
    this.requestAlunos();
  },
  filters: {
    existeRegistro(val) {
      if (val) return val;
      else return "Sem registro";
    }
  },
  computed: {
    viewportWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return "100%";
      } else {
        return "230";
      }
    },
    getLista() {
      // let findString = String(this.search).toUpperCase();
      // if (findString == "" || findString == null || findString == undefined) {
      //   return this.alunos;
      // }

      let list = this.alunos.filter(item => {
        let NameUp = normalize(item.nome);
        let nomeFormandoUp = normalize(item.nomeFormando);
        let SearchUp = normalize(this.search);
        let SobreNome = normalize(item.sobreNome);
        let userId = String(item.usuarioId);
        return (
          NameUp.match(SearchUp) ||
          SobreNome.match(SearchUp) ||
          nomeFormandoUp.match(SearchUp) ||
          userId.match(SearchUp)
        );
      });

      return list.filter(item => {
        if (this.filtro == "1") return true;
        else if (this.filtro == "2") return item.permissaoTurma == "COMISSAO";
        else if (this.filtro == "3") return item.conviteStatus == "PENDENTE";
        else if (this.filtro == "4")
          return item.conviteStatus == "NAO_AUTORIZADO";
        else if (this.filtro == "5")
          return item.conviteStatus == "CONTRATO_CANCELADO";
        else if (this.filtro == "6") return item.conviteStatus == "AUTORIZADO";
      });
    }
  },
  methods: {
    closeNovaContribuicao() {
      this.payloadUser = {};
      this.modalNovaContribuicao = false;
    },
    novaContribuicao(item) {
      this.payloadUser = item;
      this.modalNovaContribuicao = true;
    },
    closeContribuicoes() {
      this.payloadUser = {};
      this.modalContribuicoes = false;
    },
    abrirContribuicoes(item) {
      this.payloadUser = item;
      this.modalContribuicoes = true;
    },
    openDialogEdit(item) {
      this.selectedItem = item;
      this.modalEditar = true;
    },
    handleClose() {
      this.selectedItem = {};
      this.modalEditar = false;
    },
    handleAlterado() {
      this.requestAlunos();
    },
    requestAlunos() {
      serviceTurma
        .getMetaAlunos()
        .then(resp => {
          this.alunos = resp.data;
          this.alunos.sort((a, b) => {
            const nameA = normalize(a["nomeFormando"]);
            const nameB = normalize(b["nomeFormando"]);

            if (!nameA) {
              return 1;
            }
            if (!nameB) {
              return -1;
            }

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
        })
        .catch(err => {
          this.$root.errorModal(err.data);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.br-20 {
  border-radius: 20px;
}
.cardCota {
  display: inline-grid;
  margin: 10px;
  background-color: white;
  width: 250px;
  min-width: 250px;
  min-height: 260px;
  border-radius: 20px;
  text-align: center;
  &.userContain {
    //grid-template-columns: 1fr;
    display: inline-block;
  }
  @media only screen and (max-width: 560px) {
    width: 100%;
  }

  &.plusBtn {
    align-content: center;
    align-items: center;
    align-self: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    vertical-align: center;
    min-height: 330px;
  }
}
.menu {
  position: absolute !important;
  margin-top: -100px;
  margin-left: 175px;
}
.descricaoADD {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  padding-top: 5px;
  color: #778192;
}
.dadosFinanceiros {
  color: #2f3a4a;
  font-family: Roboto !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
.txtFormando {
  padding: 10px;
  color: #2f3a4a;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
}
.containDescricao {
  padding: 10px;
  .nomecompleto {
    color: #2f3a4a;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .telefone {
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    padding-top: 5px;
    color: #778192;
  }
  .email {
    padding-top: 5px;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    color: #778192;
  }
}
.totalMensalidade {
  padding: 10px 0px;
  .texto {
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #6e6b7b !important;
  }
  .valor {
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 23px !important;
    line-height: 34px !important;
    color: #8f85ff !important;
  }
}
</style>
