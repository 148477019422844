<template>
  <div>
    <v-timeline dense>
      <v-timeline-item
        :color="getColorType(item.status)"
        small
        dense
        :key="index"
        v-for="(item, index) in lista"
        fill-dot
      >
        <div>
          <div
            :class="`headline mb-2`"
            :style="{ color: getColorType(item.status) }"
          >
            <div class="text-subtitle-1 font-weight-bold">
              {{ getNameType(item.status) }}
            </div>
          </div>
          <div class="pa-1 text-left">
            <div class="text-subtitle-2">
              <div class="py-1 text-body-2 font-weight-bold">
                Data de vencimento
              </div>
              <div class="text-body-2">
                {{ item.dataVencimento | getDataTime }}
              </div>
              <div class="py-1 text-body-2 font-weight-bold">
                Descrição
              </div>
              <div class="text-body-2 mb-1">
                {{ item.descricao | existeRegistro }}
              </div>
              <div class="text-body-1 font-weight-bold py-2">
                {{ item.valor | currency }}
              </div>

              <div v-if="item" class="pt-1 ">
                <div v-if="item" class="text-right">
                  <v-btn
                    icon
                    color="secondary"
                    @click="abrirEditar(item)"
                    title="Editar"
                    :disabled="!PermissaoParaEditar(item)"
                  >
                    <v-icon
                      v-if="!PermissaoParaEditar(item)"
                      size="20"
                      color="black"
                      >mdi-lock</v-icon
                    >
                    <v-icon v-else size="20" color="#7275FF">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="secondary"
                    @click="excluirContribuicao(item)"
                    title="Editar"
                    :disabled="!PermissaoParaRemover(item)"
                  >
                    <v-icon
                      v-if="!PermissaoParaRemover(item)"
                      size="20"
                      color="black"
                      >mdi-lock</v-icon
                    >
                    <v-icon v-else size="20" color="red">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                  <v-btn
                    primary
                    :disabled="PermissaoParaGerarPAG(item)"
                    @click="abrirGerarContribuicao(item)"
                    icon
                  >
                    <v-icon size="20" color="green">
                      mdi-cash-sync
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import {
  colorsForType,
  statusDisplay
} from "@/constants/participante/statusDePagamento";
import moment from "moment";

export default {
  props: {
    lista: {
      required: true,
      type: Array
    }
  },
  methods: {
    abrirEditar(item) {
      this.$emit("editarItem", item);
    },
    excluirContribuicao(item) {
      this.$emit("excluirContribuicao", item);
    },
    abrirGerarContribuicao(item) {
      this.$emit("abrirGerarContribuicao", item);
    },
    isAdminUser() {
      return this.$store.getters["aluno/getPermissaoTurmaAtiva"] == "ADMIN";
    },
    PermissaoParaGerarPAG(item) {
      if (!this.isAdminUser()) {
        return true;
      }
      if (item.status == "PAGO") {
        return true;
      }
      if (item.status == "EXPIRADO" || item.status == "NAO_PAGO") {
        return false;
      }
    },
    PermissaoParaRemover(item) {
      if (!this.isAdminUser()) {
        return false;
      }
      if (item.status == "PAGO") {
        return false;
      }
      if (item.status == "EXPIRADO" || item.status == "NAO_PAGO") {
        return true;
      }
    },
    PermissaoParaEditar(item) {
      if (!this.isAdminUser()) {
        return false;
      }
      if (item.status == "PAGO") {
        return false;
      }
      if (item.status == "EXPIRADO" || item.status == "NAO_PAGO") {
        return true;
      }
    },
    regrasParaEditar(item) {
      if (item.status !== "NAO_PAGO") {
        return true;
      }
      if (item.boletoId) {
        return true;
      }
      return false;
    },
    getNameType(type) {
      return statusDisplay[type];
    },
    getColorType(type) {
      return colorsForType[type];
    }
  },
  filters: {
    getDataTime(tempo) {
      if (!tempo) return "Sem registro";
      return moment(tempo).format("DD/MM/YYYY");
    },
    existeRegistro(item) {
      if (!item) return "Sem registro";
      else return item;
    }
  }
};
</script>

<style></style>
