<template>
  <div>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="600">
        <v-card class="mx-auto pa-5">
          <div class="text-h6">
            Novo participante
            <div class="fechar" @click="fechar()">
              <v-icon medium> mdi-close </v-icon>
            </div>
          </div>

          <div class="pa-2">
            <v-text-field
              name="nome"
              label="Nome completo"
              v-model="include.nome"
            ></v-text-field>
            <v-text-field
              name="email"
              label="Email"
              v-model="include.email"
            ></v-text-field>
            <v-row>
              <v-col cols="12">
                <!--
                <v-text-field
                  type="number"
                  v-model="include.meta"
                  name="metaContribuicao"
                  label="Meta Contribuição"
                  prefix="R$ "
                  :disabled="loading"
                ></v-text-field>
                -->
                <v-text-field
                  v-formata-moeda="include.meta"
                  name="metaContribuicao"
                  label="Meta Contribuição"
                  prefix="R$ "
                  v-model.lazy="include.meta"
                  v-money="$root.money"
                />
              </v-col>
            </v-row>
            <v-card class="text-center elevation-0">
              <div class="pa-2 text-overline">Meta de contribuição</div>
              <div class="pa-2 text-h5 font-weight-bold primary--text">
                R$ {{ include.meta }}
              </div>
            </v-card>
          </div>
          <div class="pb-5">
            <center>
              <v-btn
                :loading="loading"
                color="primary"
                x-large
                @click="addUser()"
                >Adicionar</v-btn
              >
            </center>
          </div>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import turmaControlller from "@/middleware/turmaController";
export default {
  props: ["turmaAtiva"],
  data() {
    return {
      dialog: true,
      resp: "",
      loading: false,
      include: {
        email: "",
        nome: "",
        meta: 0
      }
    };
  },
  methods: {
    fechar() {
      this.$emit("handleClose");
    },
    validaEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    tratamentoValores() {
      this.include.meta = this.$root.converteValor(this.include.meta);
    },
    addUser() {
      this.tratamentoValores();
      if (!this.validaEmail(this.include.email)) {
        this.$root.errorModal("Email inválido!");
        return false;
      }
      if (this.include.meta < 0) {
        this.$root.errorModal("Meta não pode ser negativa");
        return false;
      }
      this.loading = true;
      let turma = this.turmaAtiva;
      turmaControlller
        .addAlunoTurma(turma, this.include)
        .then(resp => {
          //this.$root.ATUALIZA_DADOS(); //TODO:  debugar old feature exists new feature via action
          this.loading = false;
          this.$root.sucessoModal(resp.data);
          this.$emit("handleUpdate");
          this.$emit("handleClose");
        })
        .catch(err => {
          this.loading = false;
          this.$root.errorModal(err.data);
          console.log(err.data);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.titulo {
  font-size: 30px;
  color: #37447e;
  padding: 10px 0px;
}
.fechar {
  display: inline-block;
  position: absolute;
  right: 20px;
  cursor: pointer;
}
</style>
